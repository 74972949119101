/* You can add global styles to this file, and also import other style files */
@import url(~bootstrap/dist/css/bootstrap.css);
@import url(~font-awesome/css/font-awesome.css);

/* Angular material*/
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';

/* Add application styles & imports to this file! */
.cursor-all-scroll {
    cursor: all-scroll;
  }
  a:hover{
    cursor: pointer;
  }

  .ioBlue{
    background-color:#21374C;
    color: #fff;
    border-color: #21374C;
  }

  .useraccount{
    white-space: nowrap;
    text-align:center;
    color:#0B0B61; 
    font-weight: bold;
  }
  .oewaGold{
    background-color:#cccc99;    
    border-color: #cccc99;    
    color:#0B0B61; /*color: black; am 17052021 geändert*/
    font-weight: bold; /* am 17052021 hinzugefügt*/
  }

  .no-click {
    pointer-events: none;
}

.fitadvertisingPage{
  margin-left: 200px;
  margin-right: 900px; 

  height: auto;
  width: auto;
  position: relative; 
}

.fitwebcatalogPage{
  margin-left: 60px;
  margin-right: 60px; 

  height: auto;
  width: auto;
  position: relative; 
}

.fitAdminPage{
  margin-left: 80px;
  margin-right: 80px; 
}

.fitofferedit{
  margin-left: 100px;
  margin-right:300px; 
  padding: 1px; 
}
.fitdatenpflegePage{
  margin-left: 300px;
  margin-right: 60px;
}

.fitordercenterPage{
  margin-left: 300px;
  margin-right: 1350px;
}

.fitordercenterPagenG{
  margin-left: 600px;
  margin-right: 1000px;
}

.fitordercenterPagenGLabel{
  margin-left: 500px;
  margin-right: 1000px;
}
.fitordercenterPagenGLabelgewaehlt{
  margin-left: 918px;
  margin-right: 1000px;
}

.fitordercenterPagenGLabel, .fitordercenterPagenGLabelgewaehlt{
  background-color: #cccc99;
  font-size: 14px;
  color: #0B0B61;
  font-weight: bold;
  text-transform: uppercase;
}

.fitiomaApplication{
  margin-left: 300px;
  margin-right: 1200px;
}

.fitordercenterDetail{
  width: 300px;
  height: 350px;
}

.fitiomaSummary{
  margin-left: 50px;
  margin-right: 300px;
}

.fitOfferlistPage{
  margin-left: 40px;
  margin-right: 60px;  
}

.fitcustomerdatacorner{
  margin-left: 100px;
  margin-right: 50px;
 
}

.signindivform{
  border-radius: 15px 50px; /*50px 30px 5px;*/
  background: rgb(249, 250, 248);  
  width: 400px;
  height: 490px; 
}

  .oewacontainer {    
    /*margin-left: 100px;*/
    /*margin-right: 100px;*/

    height: auto;
    margin: 0;
    width: 1000px;
    position: absolute;

  }

  .fitcustomerdatacorner, .fitOfferlistPage, .fitdatenpflegePage, .fitofferedit, .fitAdminPage{
  height: auto;
  width: auto;
  position: relative; 
  }

  .customerdatacorner, .offerlistcorner, .signincontainer, .adminpagecorner{ 
    padding: 20px;
  }
  .customerdatacorner, .signindivform, .offerlistcorner, .signincontainer, .adminpagecorner, .offereditcorner, .fitcustomerdatacorner{     
    border: 1px solid #cccc99; 
  }

  .offereditcorner{ 
    padding: 1px;    
  }

  .offerlistcorner{
  margin-left: 20px;
  position: absolute;
  }

  .customerdatacorner, .offerlistcorner, .adminpagecorner, .offereditcorner, .editmobileoffercorner, .fitofferedit, .fitcustomerdatacorner{
    border-radius: 15px; /*50px 30px 5px;*/    
  }

  .offereditcorner{
    width: 1500px;/*408*/
    height: 920px;/*808*/    
    margin-left: 200px;
    margin-right: 50px;
  }

  .editmobileoffercorner
  {
    width: 930px;/*408*/
    height: 920px;/*808*/    
    margin-left: 300px;
    margin-right: 400px;
  }
  

  .customerdatacorner {       
    width: 1305px;
    height: 750px;    
    margin-left: 175px;
    margin-right: 100px;
  }

  .offerlistcorner, .adminpagecorner {     
    /*width: 1400px;*//*ausblenden damit die Breite sich nur an oewacontainer margin bregenzt*/
    height: auto;
    width: 1490px;
    position: absolute; 

  }

  .signincontainer, .signoutcontainer {
    border-radius: 15px 50px; /*50px 30px 5px;*/
    background: rgb(249, 250, 248);
    width: 400px;
    height: 475px; 
    margin-left: 400px;
    margin-right: 400px;
  }

  .required:after{
    content:"*";
  }
  .requiredBefore:before{
    content:"*";
  }
  #rcorners1,  #rcorners2, #rcornersofferedit,  #rcornersofferedit2, #rcornersoffereditmobile, #rcornersofferedit2mobile{
    /*background: rgb(249, 250, 248);
   
    background:white;*/
    background: rgb(244, 245, 243);
    padding: 20px; 
  }


  #rcornersoffereditmobile{
    border-radius: 15px; 
    width: 400px;
    height: 700px; 
  }

  #rcorners1 {
    border-radius: 15px 50px; /*50px 30px 5px;*/
    width: 800px;
    height: 250px; 
  }

  #rcorners2 {
    border-radius: 15px; /*50px 30px 5px;*/
    width: 400px;
    height: 375px; 
  }
  
  #rcornersofferedit {
    border-radius: 15px; 
    width: 400px;
    height: 830px; 
  }

  #rcornersofferedit2 {
    border-radius: 15px;
    width: 500px;
    height: 830px; 
  }

  #rcornersofferedit2mobile
  {
    border-radius: 15px;
    width: 500px;
    height: 700px; 
  }

  #margin,  #rcorners2 { 
    margin:10px;
  }
/* Mat accordion Breite in agreement Komponent*/
  mat-expansion-panel {
    width: 80%;
  }

/*für mat card in services/Meldungen Untermenü*/
  .membernewscard
{
  max-width: 1100px; 
}
 
.webcatalogcard
{
  max-width: 700px; 
}



.membernewscard,  .helpcard, .webcatalogcard, .fitagreementpage
{
margin-left: 100px;
margin-right: 100px;
}

/*für mat card in Hilfe Menü*/
  .helpcard {
    max-width: 700px; /*400px;*/
 }
  
.validationwarningbutton {
  background-color: white; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
} 

.invalidCharacterWarning {
  background-color: white;
}

.invalidCharacterWarning, .emptyRequiredFieldWarning{
font-size: 11px;
}

.emptyRequiredFieldWarning {
  /*background-color: #e595cb;*/ /* #cccc99;*/ /*red;*/
  color: black ;/*white;*/
    width: 200px;
    height: 12px;
    padding: 10px;
    border: 1px solid red;
}

.invalidCharacterWarning, .emptyRequiredFieldWarning, .title{
  font-weight: bold;
}

.invalidCharacterWarning{
  color:#e595cb; /*red*/
}

.unconfirmedlocal,  .pendingChangeStyle, .confirmedLocalDelete, .requiredBefore:before, .required:after{
  color: red;
}
.confirmedLocalDelete{
  text-decoration: line-through;
}


  .pendingstyle, .pendingChangeStyle, .nopendingChangeStyle{
    font-weight:bold;
    font-size:13px;   
 
  }

  .pendingstyle{
    background: #0B0B61;
    }

 
  .nopendingChangeStyle
  {
    color:green;
    text-align: center;
  }

 .matradiooutercircle{
    border-color:red;
  }

  .font{
    
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif; 
    font-size: 14px; 
    font-style: normal;
  }
  .centered, .columntitle, .titlecentered{
    text-align: center;
  }

 .columntitle{
    color:blue;
  }

  .title{
    font-weight: bold;
    font-size: 14px;
    background-color: #cccc99; 
    color: black;
  }

#adminPageColumnstyle{
  font-style: italic;  
  font-weight: bold  
}  


#styleOfDataCat{
    text-align: center;
    font-weight: bold;    
    background-color:#cccc99;
    font-size: 18px;
    /*color: blue;*/
}

#particularRow  {
    background-color:white;
}

#styleOffername { 
    background-color: white;
    font-weight: bold;
}

#tdspace{
    width: 10%;
}

.checkboxsize{  
    transform : scale(1.6); 
}

.mat-sort-header-arrow {
  opacity: .4 !important;
}

 /* auskommentiert, Farbe des Sortierungspfeils
.mat-sort-header-stem, .mat-sort-header-arrow, th.mat-sort-header-sorted  {
color: green !important;

}*/



table {
  table-layout: auto;
  width: 100%
  
}

#menuButton, .menuButton{
  margin-left: 5px;
  margin-right: 5px;
  padding: 2px;
  line-height: 40px;
  background-color: #cccc99; /* white;*/ /*Farbe für Menü-Buttons*/
    
}

.menuButton:hover, #menuButton:hover
{
 background-color:gray!important; /*background des Buttons wenn der Maus über ein Menubutton ist*/
 color:white; /*Textcolor wenn Maus über ein Menubutton ist*/
}

#space{
  margin-left: 5px;
  margin-right: 5px;
  padding: 2px;

}
#menustyle, #space, #menuButton, .menuButton{
  color: #0B0B61; /*Textfarbe für Menü*/
  font-weight: bold;

}

/* navbar */
.navbar-default {
  background-color: #0B0B61; 
  border-color: #0B0B61; 
}

.matcard{
  background-color: #0B0B61; 
  border-color: #0B0B61; 
}

#space_old{
  /*text-transform: uppercase;*/
  outline: none;
  border: 0;
  /*background-color: #673a9a;*/
  /*line-height: 40px;*/
  color: #fff;    
  display: inline-block;
  /*font-size: 16px;*/
  /*margin: 10px;*/
  cursor: pointer;
}


body { 
  font-family: Roboto, Arial, sans-serif;
  margin: 0;
}

.basic-container {
  padding: 30px;
}

.version-info {
  font-size: 8pt;
  float: right;
}

[mat-dialog-title] {
  margin: -24px -24px 0px -24px !important;
  padding: 10px 24px;
  background: #369;
  color: #fff;
  cursor: move;
}


[mat-dialog-draggable-title] {
    margin: -24px -24px 0px -24px !important;
    padding: 10px 24px;
    /*background: #369;*/
    background: #cccc99;
    /*color: #fff;*/
    cursor: move;
    font-size: 12pt;
    font-weight: bold
  }
  



/*
.mat-accent, .mat-radio-checked, .mat-radio-outer-circle{
    border-color:rgb(66, 134, 244); 
  }
  
 .mat-accent, .mat-radio-inner-circle{
    color:rgb(66, 134, 244);
    background-color:rgb(66, 134, 244) ;
  }*/
  